import React from "react";
import styled from "styled-components";

import { device } from "./Variables";
import { MainContainer, Content } from "./Styles";
import MapMembershipLogos from "../helpers/MapMembershipLogos";

const ContainerImgs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;

  @media ${device.mobileS} {
    flex-direction: column;
    img {
      max-width: 40%;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    img {
      max-width: 50%;
    }
  }
  @media ${device.tablet} {
    flex-direction: column;
    img {
      max-width: 20%;
    }
  }

  @media ${device.laptop} {
    flex-direction: row;
    img {
      max-width: 50%;
    }
  }
  @media ${device.desktop} {
    flex-direction: row;
    img {
      max-width: 40%;
    }
  }
`;

const MembershipLogos = () => {
  return (
    <>
      <MainContainer>
        <Content padding1>
          <ContainerImgs>
            <MapMembershipLogos />
          </ContainerImgs>
        </Content>
      </MainContainer>
    </>
  );
};

export default MembershipLogos;
