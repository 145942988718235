import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { StaticData } from "../Messages";
// import {ImgsExport } from "../Messages";
import { Colors, device } from "../Variables";
import { MainContainer, Content, PrimaryButton, SecondaryButton } from "../Styles";

// const ImgContainer = styled.img`
//     height: auto;
//     width: 60%;
//     @media ${device.mobileS} {
//         width: 60%;
//     }

//     @media ${device.mobileL} {
//         width: 60%;
//     }
//     @media ${device.tablet} {
//         width: 35%;
//     }

//     @media ${device.laptopL} {
//         width: 20%;
//     }
//     @media ${device.desktop} {
//         width: 15%;
//     }
// `;

const NavLinks = styled(Link)`
    color: ${Colors.ColorD};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    font-weight: 400;
    &:hover {
        color: ${Colors.ColorC};
        font-weight: 800;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media ${device.mobileS} {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    @media ${device.mobileL} {
        flex-direction: column-reverse;
        gap: 2rem;
    }
    @media ${device.tablet} {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    @media ${device.laptop} {
        flex-direction: row;
        gap: 0;
    }
    @media ${device.desktop} {
        flex-direction: row;
        gap: 0;
    }
`;

const ContainerData = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0.5rem;
    text-align: left;
    align-items: flex-start;
    @media ${device.mobileS} {
        text-align: center;
        align-items: center;
    }

    @media ${device.mobileL} {
        text-align: center;
        align-items: center;
    }
    @media ${device.tablet} {
        text-align: center;
        align-items: center;
    }

    @media ${device.laptop} {
        text-align: left;
        align-items: center;
    }
    @media ${device.desktop} {
        text-align: left;
        align-items: center;
    }
`;

const FlexTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`;

const FlexData = styled(FlexTitle)`
    flex-direction: row;
    gap: 5px;
`;

const TitleLogo = styled.p`
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
    color: #636255;
    margin-bottom: 10px;
`;
const SubTitleLogo = styled(TitleLogo)`
    font-size: 1rem;
    font-weight: 300;
`;

const SubTitleLogo2 = styled(SubTitleLogo)`
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
`;

const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
`;
const SubTitleLogo3 = styled(SubTitleLogo2)`
        font-weight:900;
        font-style:italic;
        margin-bottom:10px;
`;

const Header = () => {
    return (
        <div>
            <MainContainer background2>
                <Content padding1>
                    <HeaderContainer>
                        <ContainerData>
                            <FlexTitle>
                                <TitleLogo>{StaticData.Name}</TitleLogo>
                                <SubTitleLogo>{StaticData.Title}</SubTitleLogo>
                                <SubTitleLogo3>{StaticData.Title3}</SubTitleLogo3>
                            </FlexTitle>
                            <FlexData>
                                <SubTitleLogo2>{StaticData.MN}</SubTitleLogo2>
                                <SubTitleLogo2>{StaticData.MP}</SubTitleLogo2>
                            </FlexData>
                            <ContainerButtons>
                                <NavLinks to="/biography">
                                    <PrimaryButton>Biografia</PrimaryButton>
                                </NavLinks>
                                <NavLinks to="/contactme">
                                    <SecondaryButton>Contacto</SecondaryButton>
                                </NavLinks>
                            </ContainerButtons>
                        </ContainerData>

                        {/* <ImgContainer src={ImgsExport.ImgLogo} alt="manuelitaelias" /> */}
                    </HeaderContainer>
                </Content>
            </MainContainer>
        </div>
    );
};

export default Header;
