import React, { useState } from "react";

import Lottie3 from "../Lottie3";
import { MainContainer, Content } from "../Styles";
import { PhilosophyData } from "../Messages";
import { TextContainer } from "../Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Philosophy = () => {
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 500);

  return (
    <MainContainer background2>
      <Content padding1>
        <>
          {show ? (
            <div
              data-aos="fade-in"
              data-aos-offset="200"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-once="true"
            >
              {PhilosophyData.map((philosophy, index) => (
                <TextContainer key={index}>
                  {philosophy.PhilosophyTitle && (
                    <h2>{philosophy.PhilosophyTitle}</h2>
                  )}
                  {philosophy.PhilosophyParagraph && (
                    <p>{philosophy.PhilosophyParagraph}</p>
                  )}
                </TextContainer>
              ))}
            </div>
          ) : (
            <Lottie3 />
          )}
        </>
      </Content>
    </MainContainer>
  );
};

export default Philosophy;
