export const Colors = {
    //Colors
    ColorI: "#000000", //Negro

    ColorA: "#434343fc", //Gris Oscuro
    ColorB: "#f5f5ea", // Gris Claro
    ColorE: "#b8b69d", //Gris Claro2

    ColorG: "#25d366", // Verde Whapp
    ColorG2: "#44b66f", // Verde Hover Whapp


    ColorH: "#636255", //Verde Oscuro
    ColorC: "#9a9882", //Verde Claro

    ColorF: "#f5f5eade", // Blanco Translucido
    ColorD: "#ffffff", // Blanco

    //Sizes and Fonts
    GeneralFont: "font-family: 'Raleway', sans-serif;",
    WappFont: "font-family: 'Titillium Web', sans-serif;",
    BorderRadius: "0",
    Transitions: "400ms",
};

export const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};
