import React from "react";
import styled from "styled-components";

import { BioData } from "../components/Messages";

import { device, Colors } from "../components/Variables";
import { ImgsExport } from "../components/Messages";
import { TextContainer } from "../components/Styles";

const TitleLogo = styled.h1`
  margin: 0;
  font-weight: 600;
  color: #636255;
  margin-bottom: 10px;
  text-align: center;
  color: black;
  text-transform: uppercase !important;

  @media ${device.mobileS} {
    text-align: center;
  }

  @media ${device.mobileL} {
    text-align: center;
  }
  @media ${device.tablet} {
    text-align: center;
  }

  @media ${device.laptop} {
    text-align: start;
  }
  @media ${device.desktop} {
    text-align: start;
  }
`;

const SubTitleLogo = styled(TitleLogo)`
  font-size: 1rem !important;
  font-weight: 600;
  padding: 0 0 1rem 0;
  text-transform: capitalize !important;
  border-bottom: 2px solid ${Colors.ColorE};
  color: ${Colors.ColorA};
  margin: 0 0 1rem 0;
`;

const ImgContainer = styled.img`
  margin: 0 0 0 15px;
  height: auto;
  border-radius: ${Colors.BorderRadius};
  border: none;
  box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);
  @media ${device.mobileS} {
    width: 300px;
  }

  @media ${device.mobileL} {
    width: 300px;
  }
  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptop} {
    width: 250px;
  }
  @media ${device.desktop} {
    width: 250px;
  }
`;

const BioContainer = styled.div`
  padding: 0 15px 0 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }
  @media ${device.desktop} {
    flex-direction: row;
  }
  p {
    margin: 0;
    padding: 10px;
  }
`;

const Bullets = styled.div`
  text-align: end;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
  }
  h1 {
    font-size: 1.2rem;
    padding: 0 15px 0 0;
  }
  p {
    padding: 0 15px 10px 0;
    text-align: end;
  }
`;

function Item({ title, subtitle, paragraph, actbullet, acttitle }) {
  return (
    <>
      <div>
        {title && <TitleLogo>{title}</TitleLogo>}
        {subtitle && <SubTitleLogo>{subtitle}</SubTitleLogo>}
        {paragraph && <p>{paragraph}</p>}
      </div>
      <Bullets>
        {acttitle && <h1>{acttitle}</h1>}
        {actbullet && (
          <div>
            <ul>{actbullet && <p>{actbullet}</p>}</ul>
          </div>
        )}
      </Bullets>
    </>
  );
}

const MapBiography = () => {
  return (
    <TextContainer>
      <div>
        {BioData.map((biodata, index) => (
          <div key={index}>
            <Item
              key={index}
              title={biodata.BioParagraphTitle}
              subtitle={biodata.BioParagraphSubTitle}
            />
          </div>
        ))}
      </div>
      <BioContainer>
        <>
          <ImgContainer src={ImgsExport.ImgBio} alt="manuelitaeliasbiography" />
          <div>
            {BioData.map((biodata, index) => (
              <div key={index}>
                <Item key={index} paragraph={biodata.BioParagraph} />
              </div>
            ))}
          </div>
        </>
      </BioContainer>
      <div>
        {BioData.map((biodata, index) => (
          <div key={index}>
            <Item
              key={index}
              acttitle={biodata.CurrentActivityTitle}
              actbullet={biodata.CurrentActivityBullet}
            />
          </div>
        ))}
      </div>
    </TextContainer>
  );
};

export default MapBiography;
