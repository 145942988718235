import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

import { TreatmentsData } from "../components/Messages";

import { Colors, device } from "../components/Variables";

import { ServiceCard, ServiceCardContent, SecondaryButton, NavigationLinks } from "../components/Styles";
import Icons from "../components/Icons";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Modal = styled.div`
    border-radius: 5px;
    background-color: #dfe0d6;
    padding: 2rem;
    z-index: 1000;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    justify-items: center;
    margin: 0 auto;
    @media ${device.mobileS} {
        width: 70%;
    }

    @media ${device.mobileL} {
        width: 80%;
    }
    @media ${device.tablet} {
        width: 60%;
    }

    @media ${device.laptop} {
        width: 35%;
    }

    @media ${device.desktop} {
        width: 35%;
    }
`;

const OVERLAY_STYLE = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#2a2a1cde",
    zIndex: 1000,
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "start",
    justifyItems: "center",
    paddingTop: "2rem",
};

const TITLE_STYLE = {
    fontSize: "16px",
    margin: 2,
    textAlign: "center",
    textTransform: "uppercase",
    paddingBottom: "30px",
};

const PARAGRAPH_STYLE = {
    fontSize: "1rem",
    margin: 0,
    textAlign: "justify",
    width: "100%",
    paddingBottom: "20px",
};

const HEADER_STYLE = {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
};

const BUTTON_STYLE_CLOSE = {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    zIndex: "99999",
    margin: "1rem",
};
const IconWrapper = styled.div`
    border-radius: 100%;
    padding: 1.3rem;
    svg {
        width: 5rem;
        height: 5rem;
        transition: ${Colors.Transitions};
    }
`;

export const IconWrapper2 = styled.div`
    display: flex;
    background-color: #3b3b37;
    border: 2px solid ${Colors.ColorD};
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: ${Colors.Transitions};
    &:hover {
        background-color: #b1ae90;
        svg {
            stroke: ${Colors.ColorD};
        }
    }
    svg {
        stroke: ${Colors.ColorD};
        stroke-width: 2;
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const MapTreatments = () => {
    const [popupcontent, setpopupcontent] = useState([]);
    const [popuptogle, setpopuptogle] = useState(false);
    const changecontent = (item) => {
        setpopupcontent([item]);
        setpopuptogle(!popuptogle);
    };

    const ConsultaWapp = `https://api.whatsapp.com/send?phone=5491130080722&text=Hola!%20Quisiera%20hacer%20una%20consulta%20sobre%20el%20tratamiento%20de%20`;

    return (
        <>
            {TreatmentsData.map((item, index) => (
                <div
                    key={index}
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-delay="300"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                >
                    <ServiceCard>
                        <ServiceCardContent>
                            <>
                                {item.TreatmentIcon && (
                                    <IconWrapper>
                                        <ReactSVG src={item.TreatmentIcon} />
                                    </IconWrapper>
                                )}
                                {item.TreatmentName && <h5>{item.TreatmentName}</h5>}

                                <SecondaryButton onClick={() => changecontent(item)}>Leer mas</SecondaryButton>
                            </>
                        </ServiceCardContent>
                    </ServiceCard>
                </div>
            ))}
            <>
                {popuptogle && (
                    <>
                        {popupcontent.map((pop) => {
                            return ReactDOM.createPortal(
                                <div style={OVERLAY_STYLE} key={popupcontent.Id} onClick={changecontent}>
                                    <button type="submit" onClick={changecontent} style={BUTTON_STYLE_CLOSE}>
                                        <IconWrapper2>
                                            <Icons name="burger2" />
                                        </IconWrapper2>
                                    </button>
                                    <Modal>
                                        <div style={HEADER_STYLE}>
                                            <h1 style={TITLE_STYLE}>{pop.TreatmentName}</h1>
                                        </div>

                                        {pop.TretmentParagraph.map((item2, index) => (
                                            <>
                                                <p key={index} style={PARAGRAPH_STYLE}>
                                                    {item2.Paragraph}
                                                </p>
                                            </>
                                        ))}

                                        {}
                                        <NavigationLinks
                                            href={`${ConsultaWapp}${pop.TreatmentName}`}
                                            title="Realizar consulta vía Whatsapp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SecondaryButton>Consultar</SecondaryButton>
                                        </NavigationLinks>
                                    </Modal>
                                </div>,
                                document.getElementById("portal")
                            );
                        })}
                    </>
                )}
            </>
        </>
    );
};

export default MapTreatments;
