import React from "react";
import styled from "styled-components";

import { Colors, device } from "../components/Variables";
import Icons from "./Icons";

import Background from "../assets/img/misc/Background.webp";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// -----------------DESKTOP CONTAINER-------------------------------

export const DesktopContent = styled.div`
    background: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    overflow-x: hidden;
    text-align: center;

    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.mobileL} {
        font-size: 16px;
    }
    @media ${device.tablet} {
        font-size: 18px;
    }

    @media ${device.laptop} {
        font-size: 18px;
    }
    @media ${device.desktop} {
        font-size: 18px;
    }
`;
export const MobilleContent = styled.div`
    // background-color: ${Colors.ColorC};
    width: 100%;
    overflow-x: hidden;
    text-align: center;

    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.mobileL} {
        font-size: 16px;
    }
    @media ${device.tablet} {
        font-size: 18px;
    }

    @media ${device.laptop} {
        font-size: 18px;
    }
    @media ${device.desktop} {
        font-size: 18px;
    }
`;

// ----------------WAPP ACTIONS------------------------------
export const WappBanner = styled.div`
${Colors.WappFont};
background-color: ${Colors.ColorE};
color:#111111;
display: block;
font-size: 30px;
font-weight: bold;
height: auto;
letter-spacing: 5px;
line-height: 1.6;
margin: 0;
padding: 1.5rem 0;
text-align:center;
text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
text-transform:none;
width 100%;
p{
  margin:0;
  padding: 0 10px;
}


@media ${device.mobileS} {
  font-size: 16px;

}

@media ${device.mobileL} {
  font-size: 16px;
}
@media ${device.tablet} {
  font-size: 20px;
}

@media ${device.laptop} {
  font-size: 20px;
}
@media ${device.desktop} {
  font-size: 20px;
}
`;

export const NavigationLinks = styled.a`
    text-decoration: none;
    color: ${Colors.NavBarBackground};
    transition: ${Colors.Transitions};
    &:hover {
        color: ${Colors.ColorC};
    }
    p {
        transition: ${Colors.Transitions};
        &:hover {
            color: white;
        }
    }
`;

export const WappFloating = () => {
    return (
        <div className="animate__animated animate__fadeIn animate__slower">
            <NavigationLinks
                href="https://api.whatsapp.com/send?phone=5491130080722&text=Hola!%20Quisiera%20hacer%20una%20consulta%20con%20la%20Dra.%20El%C3%ADas%20"
                title="Turnos por Whatsapp"
                target="_blank"
                rel="noreferrer"
            >
                <Icons name="wappfixed" />
            </NavigationLinks>
        </div>
    );
};

// ----------------MAIN CONTAINER------------------------------

const BacgrkoundChange = styled.div`
    background-color: ${changeColorBack};

    @media ${device.mobileS} {
        padding: 0;
    }

    @media ${device.mobileL} {
        padding: 0;
    }
    @media ${device.tablet} {
        padding: ${paddingAddBack};
    }

    @media ${device.laptop} {
        padding: ${paddingAddBack};
    }
    @media ${device.desktop} {
        padding: ${paddingAddBack};
    }
`;

function paddingAddBack({ padding1, padding2 }) {
    return (padding1 && "2rem 0") || (padding2 && "0");
}
function changeColorBack({ background1, background2 }) {
    return (background1 && "transparent") || (background2 && "white");
}

export function MainContainer({ padding1, padding2, background1, background2, children }) {
    return (
        <BacgrkoundChange padding1={padding1} padding2={padding2} background1={background1} background2={background2}>
            {children}
        </BacgrkoundChange>
    );
}

// ---------------SUB CONTAINER-------------------------------

const ColorChange = styled.div`
  margin: auto;
  padding: ${paddingAdd}
  background-color: ${changeColor};
  text-align: center;

  @media ${device.mobileS} {
    max-width: 100%
    border-radius: none;
  }

  @media ${device.mobileL} {
    max-width: 100%
    border-radius: none;
  }
  @media ${device.tablet} {
    max-width: 100%
    border-radius: none;
  }

  @media ${device.laptop} {
    max-width: 1440px;
    border-radius: ${Colors.BorderRadius};
  }
  @media ${device.desktop} {
    max-width: 2560px;
    border-radius: ${Colors.BorderRadius};
  }
`;

function paddingAdd({ padding1, padding2 }) {
    return (padding1 && "5rem 0 5rem 0;") || (padding2 && "2rem 0 2rem 0;");
}

function changeColor({ color1, color2 }) {
    const valueColor1 = Colors.ColorF;
    const valueColor2 = Colors.ColorD;

    return (color1 && valueColor1) || (color2 && valueColor2);
}

export function Content({ color1, color2, children, padding1, padding2 }) {
    return (
        <div>
            <ColorChange color1={color1} color2={color2} padding1={padding1} padding2={padding2}>
                <>{children}</>
            </ColorChange>
        </div>
    );
}

// ----------------NAVBAR------------------------------

const MenuContainer = styled.div`
    background-color: #434343;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    width: 100%;
    ${MenuAnimation};
    transition: ${Colors.Transitions};
    color: ${Colors.ColorD};
    z-index: 9999;
    align-items: flex-start;
`;

function MenuAnimation({ open }) {
    return (open && "height: 100vh;") || "height: 6rem;";
}

export function MenuComponent({ children, collapsed }) {
    return (
        <MenuContainer open={collapsed} className="animate__animated animate__fadeIn animate__slow">
            {children}
        </MenuContainer>
    );
}
// ---------------TEXTS--------------------------

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin: 0 auto;

    p {
        margin: 0;
        padding: 10px;
        text-align: justify;
    }
    h1,
    h4 {
        text-transform: capitalize;
        font-size: 1.5rem;
    }
    h2 {
        text-transform: uppercase;
        font-size: 1.5rem;
        padding: 0 10px;
    }

    @media ${device.mobileS} {
        width: 300px;
    }

    @media ${device.mobileL} {
        width: 375px;
    }
    @media ${device.tablet} {
        width: 768px;
    }

    @media ${device.laptop} {
        width: 1024px;
    }
    @media ${device.desktop} {
        width: 1024px;
    }
`;

// -----------------------BUTTONS--------------------------------
export const PrimaryButton = styled.button`
    background-color: #636255;
    color: ${Colors.ColorD};
    text-transform: uppercase;
    width: 8rem;
    height: 2.5rem;
    border: none;
    font-size: 0.8rem;
    margin: 1rem 0;
    border-radius: ${Colors.BorderRadius};
    cursor: pointer;
    transition: ${Colors.Transitions};
    &:hover {
        box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);
        background-color: #b1ae90 !important;
    }
`;
export const SecondaryButton = styled(PrimaryButton)`
    background-color: #3b3b37;
`;
// --------------------SERVICES-----------------------------------
export const FlexServiceSections = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media ${device.mobileS} {
        flex-direction: column;
        padding: 0;
    }

    @media ${device.mobileL} {
        flex-direction: column;
        padding: 0;
    }
    @media ${device.tablet} {
        flex-direction: row;
        padding: 0;
    }

    @media ${device.laptop} {
        flex-direction: row;
        padding: 0 2rem;
        width: 70%;
        margin: 0 auto;
    }

    @media ${device.desktop} {
        flex-direction: row;
        padding: 0 2rem;
    }
`;

export const ServiceCard = styled.div`
    position: relative;
    min-height: 1px;
    align-items: center;
    background-color: ${Colors.ColorD};
    margin: 0 auto;
    width: 20%;
    transition: ${Colors.Transitions};
    border-radius: ${Colors.BorderRadius};
    box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);

    margin: 0.5rem 0;
    @media ${device.mobileS} {
        width: 18rem;
    }

    @media ${device.mobileL} {
        width: 18rem;
    }
    @media ${device.tablet} {
        width: 18rem;
    }

    @media ${device.laptop} {
        width: 18rem;
    }

    @media ${device.desktop} {
        width: 15rem;
    }
`;
export const ServiceCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    height: 300px;
    max-height: 300px;
    h5 {
        font-size: 1rem;
        text-transform: capitalize;
        margin-top: 20px;
        height: 30px;
        max-height: 30px;
    }

    p {
        text-align: justify;
    }
    button {
        margin: 0;
        margin-top: 20px;
    }

    &:hover {
        svg {
            width: 5.5rem;
            height: 5.5rem;
        }
    }
`;

export const TitleServiceCard = styled.h5`
    text-transform: capitalize;
    font-size: 1rem;
    letter-spacing: 2px;
    margin: 20px;
    padding: 0 10px;
`;

export const ImgServiceCard = styled.img`
    width: 10rem;
    height: 10rem;
    background-color: red;
    margin: 0 auto;
    border-radius: ${Colors.BorderRadius};
`;
export const ImgServiceCardProductos = styled(ImgServiceCard)`
    width: 50%;
    height: auto;
`;

export const SectionTitles = styled.div`
    h4 {
        text-transform: capitalize;
        letter-spacing: 2px;
        font-size: 1.5rem;
        ${Colors.GeneralFont};
        text-align: center;
        margin: 10px 0;
        font-weight: 400;
    }
    p {
        padding: 20px;
        text-align: justify;
    }
    h6 {
        padding: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin: 10px 0;
    }
`;
// -------------------------------------

export const SliceColor = styled.span`
    color: ${Colors.ColorH};
    font-weight: 600;
    border-bottom: 2px solid ${Colors.ColorH};
`;
