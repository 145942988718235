import React from "react";
import { isMobile } from "react-device-detect";

import RoutesData from "../RoutesData";
import NavBarWeb from "../NavBarWeb";
import NavBarMobile from "../NavBarMobile";
import BannerWapp from "../BannerWapp";
import MembershipLogos from "../MembershipLogos";
import Footer from "../Footer";
import { DesktopContent, MobilleContent } from "../Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const MainLayout = () => {
  return (
    <div id="totop">
      <NavBarWeb />
      <NavBarMobile />
      <div>
        <>
          {isMobile ? (
            <MobilleContent className="animate__animated animate__fadeIn animate__slower">
              <RoutesData />
            </MobilleContent>
          ) : (
            <DesktopContent className="animate__animated animate__fadeIn animate__slower">
              <RoutesData />
            </DesktopContent>
          )}
        </>
      </div>
      <div
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      >
        <BannerWapp />
      </div>
      <div
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-delay="100"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      >
        <MembershipLogos />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
