import React from "react";

import MapProducts from "../../helpers/MapProducts";
import {
  MainContainer,
  Content,
  FlexServiceSections,
  SectionTitles,
  SliceColor,
  TextContainer,
} from "../Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Products = () => {
  return (
    <div>
      <MainContainer background2>
        <>
          <Content color2 padding2>
            <SectionTitles>
              <h4>
                <SliceColor>Pro</SliceColor>ductos Disponibles
              </h4>
              <></>
            </SectionTitles>
            <TextContainer>
              <p style={{ textAlign: "center" }}>
                Encontrá acá todos los productos para el cuidado de tu piel.
              </p>
            </TextContainer>

            <FlexServiceSections>
              <MapProducts />
            </FlexServiceSections>
          </Content>
        </>
      </MainContainer>
    </div>
  );
};

export default Products;
