import React from "react";
import styled from "styled-components";

import { NavigationLinks } from "./Styles";
import Icons from "./Icons";

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const Redes = () => {
    return (
        <IconWrapper>
            <NavigationLinks
                href="https://www.instagram.com/dramanuelitaelias/"
                title="Instagram"
                target="_blank"
                rel="noreferrer"
            >
                <Icons name="instagram" />
            </NavigationLinks>
            <NavigationLinks
                href="https://www.linkedin.com/in/manuelita-elias-7ab44060/"
                title="Facebook"
                target="_blank"
                rel="noreferrer"
            >
                <Icons name="facebook" />
            </NavigationLinks>
            {/* <NavigationLinks
        href="https://www.facebook.com/profile.php?id=100063582216672"
        title="LinkedIn"
        target="_blank"
        rel="noreferrer"
      >
        <Icons name="linkedin" />
      </NavigationLinks> */}
        </IconWrapper>
    );
};

export default Redes;
