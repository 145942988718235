import React from "react";
import styled from "styled-components";

import { Colors, device } from "./Variables";
// import { ImgsExport } from "./Messages";

import Lottie from "./Lottie";
import Lottie2 from "./Lottie2";

import AOS from "aos";
import "aos/dist/aos.css";

// ------------------------------------------------------------

// const ImgContainer = styled.img`
//   height: auto;
//   width: 60%;
//   @media ${device.mobileS} {
//     width: 60%;
//   }

//   @media ${device.mobileL} {
//     width: 50%;
//   }
//   @media ${device.tablet} {
//     width: 40%;
//   }

//   @media ${device.laptop} {
//     width: 20%;
//   }
//   @media ${device.desktop} {
//     width: 15%;
//   }
// `;

const Background = styled.div`
  background-color: ${Colors.ColorD};
  height: 100vh;
  position: fixed;
  overflow-y: none;
  width: 100%;
  text-align: center;
  transition: ${Colors.Transitions};
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const TitleDiv = styled.div`
  background-color: #ffffff82;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  transition: ${Colors.Transitions};
  -webkit-mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  -webkit-mask-size: 200%;
  animation: shine 2s linear infinite;

  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
  }
`;

const Titles = styled.h1`
  color: #ad9a72;
  margin: 0;
  font-weight: bolder;
  font-family: ${Colors.GeneralFont};
  color: ${Colors.ColorC};

  @media ${device.mobileS} {
        font-size: 2.5rem;
    }
  @media ${device.mobileL} {
  {

      font-size: 2.5rem;
   }

  @media ${device.tablet} {
        font-size: 3rem;
  }

  @media ${device.laptop} {
       font-size: 4rem;
    }

  @media ${device.desktop} {
      font-size: 5rem;
  }
`;
const Titles1 = styled.h2`
color: white;
margin: 0;

  font-weight: 100;
  font-family: ${Colors.GeneralFont};
  color: ${Colors.ColorH};

@media ${device.mobileS} {
font-size: 2.5rem;
}

@media ${device.mobileL} {
    font-size: 2.5rem;
}

@media ${device.tablet} {
    font-size: 3rem;
}

@media ${device.laptop} {
    font-size: 4rem;
}

@media ${device.desktop} {
    font-size: 5rem;
}
`;

const SubTitles = styled(Titles)`
 color:  #939191fc;
 font-family:  ${Colors.GeneralFont};
 margin: 0;
 text-transform: uppercase;
 font-weight: 600;

 @media ${device.mobileS} {
    font-size: 0.8rem;
    word-spacing:5px;
    }

    @media ${device.mobileL} {
       font-size: 0.8rem;
        word-spacing:5px;
    }

    @media ${device.tablet} {
        font-size: 1rem;
        word-spacing:10px;
    }

    @media ${device.laptop} {
        font-size: 1.2rem;
        word-spacing:10px;
    }

    @media ${device.desktop} {
        font-size: 1.5rem;
        word-spacing:10px;
    }`;

    const SubTitles3 = styled(Titles)`
 color:  #939191fc;
 font-family:  ${Colors.GeneralFont};
 margin: 0;
 text-transform: uppercase;
 font-weight: 600;
 font-size:16px;
 margin-top:10px;
 `

AOS.init();
export default function LoaderInitial() {
    return (
        <div>
            <Background className="animate__animated animate__fadeIn">
                <Lottie />
                <Lottie2 />
                <div>
                    <div>
                        <div
                            data-aos="fade-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-once="true"
                        >
                            {/* <ImgContainer src={ImgsExport.ImgLogo} alt="manuelitaelias" /> */}
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        data-aos="zoom-in"
                        data-aos-offset="200"
                        data-aos-delay="800"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        <TitleDiv>
                            <Titles1>Manuelita</Titles1>
                            <Titles>
                                <b>Elias</b>
                            </Titles>
                        </TitleDiv>
                    </div>

                    <div
                        data-aos="fade-in"
                        data-aos-offset="200"
                        data-aos-delay="2000"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        <SubTitles>Dermatóloga Estética & Medicina Funcional</SubTitles>
                        <SubTitles3>Desde 1998</SubTitles3>
                    </div>
                </div>
            </Background>
        </div>
    );
}
