import React from "react";

import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { Colors } from "./Variables";

// FONT AWESOME------------------------------------------------
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faWhatsapp as Whatsapp,
    faFacebookSquare as Facebook,
    faInstagramSquare as Instagram,
} from "@fortawesome/free-brands-svg-icons";

import {} from "@fortawesome/free-regular-svg-icons";
import {
    faPhone as Phone,
    faLocationDot as Location,
    faEnvelope as Email,
    faClock as Clock,
    faBars as Burger,
    faXmark as Close,
    faXmark as Error,
} from "@fortawesome/free-solid-svg-icons";

// ------------------------------------------------------------
const ColorWapp = Colors.ColorA;
const ColorWappHover = Colors.ColorC;
const ColorWappMobile = Colors.ColorG;
const ColorWappMobileHover = Colors.ColorG2;
// ------------------------------------------------------------

const IconWrapperGeneral = styled.div`
    color: ${Colors.ColorH};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 20px;
    transition: 400ms;
`;

const IconWrapperSocialMedia = styled(IconWrapperGeneral)`
    font-size: 30px;
    &:hover {
        cursor: pointer;
        color: ${Colors.ColorA};
    }
`;
const IconWrapperNavigation = styled(IconWrapperGeneral)`
    color: ${Colors.ColorD};
    font-size: 30px;
    &:hover {
        cursor: pointer;

    }
`;

const FloatWhatsapp = styled(IconWrapperSocialMedia)`
    color: ${Colors.ColorD};
    background-color: ${isMobile ? ColorWappMobile : ColorWapp};
    border-radius: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    transition: ${Colors.Transitions};
    &:hover {
        background-color: ${isMobile ? ColorWappMobileHover : ColorWappHover};
        color: ${Colors.ColorD};
    }

`;

export default function Icons({ name }) {
    switch (name) {
        //-----------------------------------
        //NAVBAR
        case "burger1":
            return (
                <IconWrapperNavigation>
                    <FontAwesomeIcon icon={Burger} />
                </IconWrapperNavigation>
            );
        case "burger2":
            return (
                <IconWrapperNavigation>
                    <FontAwesomeIcon icon={Close} />
                </IconWrapperNavigation>
            );
        //-----------------------------------
        //DATA
        case "address":
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Location} />
                </IconWrapperGeneral>
            );
        case "phone":
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Phone} />
                </IconWrapperGeneral>
            );
        case "whatsapp":
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Whatsapp} />
                </IconWrapperGeneral>
            );
        case "email":
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Email} />
                </IconWrapperGeneral>
            );
        case "time":
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Clock} />
                </IconWrapperGeneral>
            );
        //-----------------------------------

        case "instagram":
            return (
                <IconWrapperSocialMedia>
                    <FontAwesomeIcon icon={Instagram} />
                </IconWrapperSocialMedia>
            );
        case "facebook":
            return (
                <IconWrapperSocialMedia>
                    <FontAwesomeIcon icon={Facebook} />
                </IconWrapperSocialMedia>
            );
        //-----------------------------------
        //FLOAT
        case "wappfixed":
            return (
                <FloatWhatsapp>
                    <FontAwesomeIcon icon={Whatsapp} />
                </FloatWhatsapp>
            );
        //-----------------------------------

        //-----------------------------------
        //DEFAULT
        default:
            return (
                <IconWrapperGeneral>
                    <FontAwesomeIcon icon={Error} />
                </IconWrapperGeneral>
            );
    }
}
