import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { StaticData } from "./Messages";
import { Colors, device } from "./Variables";
import { MainContainer } from "./Styles";
import DataContactFull from "./DataContactFull";
import Icons from "./Icons";
import Redes from "./Redes";

const TitleSections = styled.h1`
    color: ${Colors.ColorI};
    text-decoration: none;
    font-size: 1.4rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    line-height: 30px;
    font-weight: 600;
    text-transform: capitalize;
`;

const TextSections = styled.h1`
    color: ${Colors.ColorA};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    margin: 0.5rem 0 0.5rem 0;
`;

const ContainerLogo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem 0;
    margin: 0 auto;
    width: 80%;
    border-bottom: 1px solid ${Colors.ColorE};
    a {
        text-decoration: none;
    }
`;
const TitleAlignment = styled.div`
    text-align: center;
`;

const TitleLogo = styled.p`
    margin: 0;
    font-size: 1.8rem;
    color: ${Colors.ColorH};
`;

const SubTitleLogo = styled(TitleLogo)`
    font-size: 1.2rem;
`;

const ContainerLinks = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 25px;
    margin: 0 auto;
    justify-content: center;
`;

const NavLinksLogo = styled(Link)`
    color: ${Colors.ColorA};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    line-height: 30px;
    font-weight: 600;
`;

const NavLinks = styled(NavLinksLogo)`
    color: ${Colors.ColorA};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    border-bottom: 2px dotted ${Colors.ColorE};
    line-height: 30px;
    font-weight: 600;
    &:hover {
        color: ${Colors.ColorC};
        padding-left: 10px;
    }
`;

const FooterBackground = styled.div`
    background-color: ${Colors.ColorB};
    padding: 2rem 0;
`;

const ContainerFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
    align-items: baseline;
    gap: 1rem;

    @media ${device.mobileS} {
        max-width: 320px;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    @media ${device.mobileL} {
        max-width: 425px;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    @media ${device.tablet} {
        max-width: 768px;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    @media ${device.laptop} {
        max-width: 1440px;
        align-items: baseline;
        flex-direction: row;
        text-align: left;
    }
    @media ${device.desktop} {
        max-width: 2560px;
        align-items: baseline;
        text-align: left;
    }
`;

const ContactWrapper = styled.div`
    display: Flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    @media ${device.mobileS} {
        justify-content: center;
        text-align: center;
    }

    @media ${device.mobileL} {
        justify-content: center;
        text-align: center;
    }

    @media ${device.tablet} {
        justify-content: center;
    }

    @media ${device.laptop} {
        justify-content: start;
        text-align: start;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
`;
const Copyright = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${isMobile ? "column" : "row"};
    text-align: center;
    gap: 4px;
    :align-items: center;
    background-color: ${Colors.ColorH};
    color: ${Colors.ColorD};
    padding: 1rem;
    font-size: 0.9rem;

    a {
        transition: ${Colors.Transitions};
        margin: 0;
        font-weight: 600;
        text-decoration: none;
        color: ${Colors.ColorD};

        &:hover {
            color: ${Colors.ColorC};
            font-weight: 800;
        }
    }
    p {
        margin: 0;
    }
`;

const ContainerRedes = styled.div`
    border-top: 1px solid ${Colors.ColorE};
    border-bottom: 1px solid ${Colors.ColorE};
    flex-direction: column;
    padding: 1rem 0;
    display: flex;
    gap: 1rem;
    justify-content: center;

    align-items: center;
    margin-top: 1rem;
    p {
        color: ${Colors.ColorI};
        text-decoration: none;
        font-size: 1rem;
        ${Colors.GeneralFont};
        transition: ${Colors.Transitions};
        line-height: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
    }
`;
const GridFooter = styled.div`
    width: 20rem;
`;
const GridFooter2 = styled(GridFooter)`
    width: 10rem;
`;

const Footer = () => {
    return (
        <MainContainer>
            <FooterBackground>
                <ContainerLogo>
                    <a href="#totop">
                        <TitleAlignment>
                            <TitleLogo>{StaticData.Name}</TitleLogo>
                            <SubTitleLogo>{StaticData.Title}</SubTitleLogo>
                        </TitleAlignment>
                    </a>
                </ContainerLogo>
                <ContainerFooter>
                    <GridFooter>
                        <TitleSections>Contácto Rápido</TitleSections>

                        <DataContactFull />
                    </GridFooter>
                    <GridFooter2>
                        <ContainerLinks>
                            <TitleSections>Más Páginas</TitleSections>
                            <NavLinks to="/">{"> "}Inicio</NavLinks>
                            <NavLinks to="/biography">{"> "}Biografía</NavLinks>
                            <NavLinks to="/philosophy">{"> "}Filosofía</NavLinks>
                            <NavLinks to="/treatement">{"> "}Tratamientos</NavLinks>
                            <NavLinks to="contactme">{"> "}Contacto</NavLinks>
                        </ContainerLinks>
                    </GridFooter2>
                    <GridFooter>
                        <TitleSections>Horarios De Atención</TitleSections>
                        <ContactWrapper>
                            <IconWrapper>
                                <Icons name="time" />
                            </IconWrapper>
                            <TextSections>{StaticData.Time}</TextSections>
                        </ContactWrapper>
                    </GridFooter>
                </ContainerFooter>
                <ContainerRedes>
                    <div>
                        <p>Sigueme en mis redes sociales</p>
                    </div>
                    <Redes />
                </ContainerRedes>
            </FooterBackground>

            <Copyright>
                <p>2021 - {new Date().getFullYear()}. Todos los derechos reservados, Diseño y Desarrollo por:</p>
                <a
                    href="https://www.giorgioruanova.com/"
                    target="_blank"
                    rel="noreferrer"
                    title="Giorgio Ruanova Development"
                    aria-label="Giorgio Ruanova Development"
                >
                    Giorgio Ruanova
                </a>
            </Copyright>
        </MainContainer>
    );
};

export default Footer;
