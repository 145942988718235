import React, { useState } from "react";

import MapBiography from "../../helpers/MapBiography";
import Lottie3 from "../Lottie3";
import { MainContainer, Content } from "../Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const Biography = () => {
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 500);

  return (
    <MainContainer background2>
      <Content padding1>
        <>
          {show ? (
            <div
              data-aos="fade-in"
              data-aos-offset="200"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-once="true"
            >
              <MapBiography />
            </div>
          ) : (
            <Lottie3 />
          )}
        </>
      </Content>
    </MainContainer>
  );
};

export default Biography;
