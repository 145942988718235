import React from "react";
import styled from "styled-components";

import { StaticData } from "./Messages";
import { Colors, device } from "./Variables";
import Icons from "./Icons";
import Redes from "./Redes";

const ContatInfo = styled.div`
  display: flex;
  align-items: center;
  justify.content: center;
  h1 {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
  }
  a {
    color: ${Colors.ColorD};
    text-decoration: none;
    transition: ${Colors.Transitions};
    &:hover {
      color: black;
    }
  }
  @media ${device.mobileS} {
    flex-direction: column;
    gap: 1rem;
    text-align:center;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 1rem;
    text-align:center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    gap: 5rem;

  @media ${device.laptop} {
    flex-direction: row;
    gap: 5rem;

  }
`;

const ContainerContatInfo = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    color: ${Colors.ColorD};
    ${Colors.WappFont};
    border-bottom: 2px solid ${Colors.ColorE};
    padding: 10px 0;
    background-color: ${Colors.ColorC};
`;

const ContactWrapper = styled.div`
    display: Flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;
const ContactWrapperEmail = styled.div`
    display: Flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    &:hover {
        i {
            color: black;
        }
    }
`;

const DataContact = () => {
    const emailvalue = `mailto:${StaticData.Email}`;

    return (
        <>
            <ContainerContatInfo>
                <ContatInfo>
                    <ContactWrapper>
                        <Icons name="whatsapp" />
                        <h1>{StaticData.Phone1}</h1>
                    </ContactWrapper>
                    {/* <ContactWrapper>
                        <Icons name="whatsapp" />
                        <h1>{StaticData.Phone2}</h1>
                    </ContactWrapper> */}
                    <a href={emailvalue} title="Realizar Consulta" target="_blank" rel="noreferrer">
                        <ContactWrapperEmail>
                            <Icons name="email" />
                            <h1>{StaticData.Email}</h1>
                        </ContactWrapperEmail>
                    </a>
                    <ContactWrapper>
                        <Icons name="time" />
                        <h1>{StaticData.Time}</h1>
                    </ContactWrapper>
                    <div>
                        <Redes />
                    </div>
                </ContatInfo>
            </ContainerContatInfo>
        </>
    );
};

export default DataContact;
