import React from "react";

import MapTreatments from "../../helpers/MapTreatments";
import {
  MainContainer,
  Content,
  FlexServiceSections,
  SectionTitles,
  TextContainer,
  SliceColor,
} from "../Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Treatements = () => {
  return (
    <div
      data-aos="fade-in"
      data-aos-offset="200"
      data-aos-delay="100"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <MainContainer background2>
        <>
          <Content padding1>
            <SectionTitles>
              <h4>
                <SliceColor>Tra</SliceColor>tamientos
              </h4>
              <TextContainer>
                <p>
                  Nuestra filosofía se basa en lograr el equilibrio entre la
                  belleza, la salud y la calidad de vida. La búsqueda de vivir
                  en armonía con la propia imagen es uno de los principales
                  factores que llevan a una persona a la realización de un
                  procedimiento estético.
                </p>
              </TextContainer>
            </SectionTitles>
            <FlexServiceSections>
              <MapTreatments />
            </FlexServiceSections>
          </Content>
        </>
      </MainContainer>
    </div>
  );
};

export default Treatements;
