import React, { useState } from "react";

import Lottie3 from "../Lottie3";
import Header from "../Sections/Header";
import BannerWapp from "../BannerWapp";
import Treatements from "../Pages/Treatements";
import MedCenter from "../Sections/MedCenter";
import Products from "../Sections/Products";
import ContactMe from "../Pages/ContactMe";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Index = () => {
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 500);

  return (
    <>
      {show ? (
        <>
          <div
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="0"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <Header />
          </div>
          <div
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <BannerWapp />
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <Treatements />
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <MedCenter />
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <Products />
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <ContactMe />
          </div>
        </>
      ) : (
        <Lottie3 />
      )}
    </>
  );
};

export default Index;
