import { useState } from 'react';
import ScrollToTop from './helpers/ScrollToTop';

import './App.css';

import MainLayout from './components/Layouts/MainLayout';
import { WappFloating } from './components/Styles';
import LoaderInitial from './components/LoaderInitial';




function App() {
    const [show, setShow] = useState(false);
    setTimeout(() => {
        setShow(true);
    }, 4500);


      return (
        <>
            {show ? (<>
                <div className="animate__animated animate__fadeIn animate__slow">
                    <MainLayout />
                    <ScrollToTop />
                    <WappFloating />
                </div>

            </>) : <LoaderInitial />}
        </>
    );
}

export default App;
