import React from "react";

import { MedCenter } from "../components/Messages";

import { TextContainer } from "../components/Styles";

const MapMedCenter = () => {
  return (
    <>
      {MedCenter.map((medcenter, index) => (
        <TextContainer key={index}>
          {medcenter.MedCenterParagraph && (
            <p>{medcenter.MedCenterParagraph}</p>
          )}
        </TextContainer>
      ))}
    </>
  );
};

export default MapMedCenter;
