import React from "react";
import { Routes, Route } from "react-router-dom";

import Index from "./Pages/Index";
import Biography from "./Pages/Biography";
import Philosophy from "./Pages/Philosophy";
import Treatements from "./Pages/Treatements";
import ContactMe from "./Pages/ContactMe";

export default function RoutesData() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="biography" element={<Biography />} />
      <Route path="philosophy" element={<Philosophy />} />
      <Route path="treatement" element={<Treatements />} />
      <Route path="contactme" element={<ContactMe />} />
    </Routes>
  );
}
