import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { ImgMedCenter } from "../components/Messages";

import { Colors } from "../components/Variables";
import { TextContainer } from "../components/Styles";
import Icons from "../components/Icons";

const ContainerImgs = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 98%;
    gap: 1rem;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
`;

const IconWrapper = styled.div`
    display: flex;
    background-color: grey;
    border: 2px solid white;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    svg {
        stroke: ${Colors.ColorD};
        stroke-width: 2;
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const ImgFormat = styled.img`
    max-width: 98%;
    height: auto;
    cursor: pointer;
    transition: ${Colors.Transitions};
    &:hover {
        transform: scale(1.1);
        box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);
        border-radius: 10px;
    }
`;

const IMAGES_STYLE_LARGE = {
    maxWidth: "100%",
    height: "auto",
};

const BUTTON_STYLE_CLOSE = {
    cursor: "pointer",
    position: "fixed",
    top: "-1rem",
    right: "-1rem",
    backgroundColor: "transparent",
    border: "none",
};

const OVERLAY_STYLE = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#3d3e24a8",
    zIndex: 1000,
};

const MODAL_STYLES = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e2e3daba",
    padding: "1rem",
    zIndex: 1000,
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
};

const MapCenterMedImgs = () => {
    const [popupcontent, setpopupcontent] = useState([]);
    const [popuptogle, setpopuptogle] = useState(false);
    const changecontent = (imgs) => {
        setpopupcontent([imgs]);
        setpopuptogle(!popuptogle);
    };

    return (
        <TextContainer>
            {/* ACA VIENE<----------------------- */}
            <ContainerImgs>
                {ImgMedCenter.map((imgs, index) => (
                    <div key={index}>
                        <ImgFormat src={imgs.ImgCenter} alt={index} onClick={() => changecontent(imgs)} />
                    </div>
                ))}
            </ContainerImgs>

            {popuptogle && (
                <>
                    {popupcontent.map((pop) => {
                        return ReactDOM.createPortal(
                            <div style={OVERLAY_STYLE} onClick={changecontent}>
                                <div style={MODAL_STYLES}>
                                    <button type="submit" style={BUTTON_STYLE_CLOSE} onClick={changecontent}>
                                        <IconWrapper>
                                            <Icons name="burger2" />
                                        </IconWrapper>
                                    </button>

                                    <img style={IMAGES_STYLE_LARGE} src={pop.ImgCenter} alt={pop.Id} />
                                </div>
                            </div>,
                            document.getElementById("portal")
                        );
                    })}
                </>
            )}
        </TextContainer>
    );
};

export default MapCenterMedImgs;
