import React from "react";
import styled from "styled-components";

import DataContactFull from "../DataContactFull";
import { MainContainer, Content, SliceColor } from "../Styles";
import { Colors, device } from "../Variables";

import AOS from "aos";
import "aos/dist/aos.css";

const DivFlexContact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: left;
  h2 {
    margin:0 0 20px 0;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 400;
  }
  @media ${device.mobileS} {
    flex-direction: column-reverse;
    text-align: center;
    iframe{
      width: 19rem;
      height: 15rem;
      text-align: center;

  }
  }

  @media ${device.mobileL} {
    flex-direction: column-reverse;
    text-align: center;
    iframe{
      width: 25rem;
      height: 15rem;
  }
  @media ${device.tablet} {
    flex-direction: column-reverse;
    text-align: center;
    iframe{
      width: 30rem;
      height: 20rem;
  }

  @media ${device.laptop} {
    flex-direction: row;
    text-align: left;
    iframe{
      width: 30rem;
      height: 20rem;
  }
  @media ${device.desktop} {
    flex-direction: row;
    text-align: left;
    iframe{
      width: 30rem;
      height: 20rem;
    }
  }

`;

const Map = styled.iframe`
  width: 30rem;
  height: 20rem;
  border: none;
  box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);
  border-radius: ${Colors.BorderRadius}; ;
`;

AOS.init();
const ContactMe = () => {
  return (
    <div
      data-aos="fade-in"
      data-aos-offset="200"
      data-aos-delay="100"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <MainContainer background1 padding1>
        <Content color1 padding1>
          <DivFlexContact>
            <Map
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.411611543359!2d-58.4618177!3d-34.543132299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb4206111d541%3A0xd889cbef096b9ff!2sAv.%20del%20Libertador%207766%2C%20C1429%20BMX%2C%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1711142426423!5m2!1sen!2sar"
              width="350"
              height="300"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="map"
            ></Map>

            <div>
              <h2>
                <SliceColor>Con</SliceColor>táctame
              </h2>
              <DataContactFull />
            </div>
          </DivFlexContact>
        </Content>
      </MainContainer>
    </div>
  );
};

export default ContactMe;
