import React from "react";
import styled from "styled-components";

import { Imgs } from "../components/Messages";

import { TextContainer } from "../components/Styles";

const ImgsBrand = styled.img`
  max-width: 40%;
  height: auto;
  margin: auto;
  padding: 1rem 0;
`;

const MapMembershipLogos = () => {
  return (
    <>
      {Imgs.map((imgs, index) => (
        <TextContainer key={index}>
          {imgs.ImgBrandLogo && (
            <ImgsBrand src={imgs.ImgBrandLogo} alt="memberof" />
          )}
        </TextContainer>
      ))}
    </>
  );
};

export default MapMembershipLogos;
