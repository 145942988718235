import React from "react";
import styled from "styled-components";

import { Colors } from "./Variables";
import Redes from "./Redes";

const ContainerContatInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${Colors.ColorD};
    ${Colors.WappFont};
    border-bottom: 2px solid ${Colors.ColorE};
    padding: 10px 0;
    align-items: center;
    background-color: ${Colors.ColorC};
`;

const DataContactMobile = () => {
    return (
        <>
            <ContainerContatInfo>
                <Redes />
            </ContainerContatInfo>
        </>
    );
};

export default DataContactMobile;
