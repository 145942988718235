import React from "react";
import styled from "styled-components";

import { ProductsArray } from "../components/Messages";

import { Colors } from "../components/Variables";
import {
  ServiceCard,
  ServiceCardContent,
  SecondaryButton,
  NavigationLinks,
} from "../components/Styles";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Grid = styled.div`
  display: flex;
`;

const ImgProds = styled.img`
  width: auto;
  max-height: 50%;
  margin: 0;
  // filter: saturate(3);
  margin: 0.5rem 0;
  transition: ${Colors.Transitions};
`;

const ProdCardContent = styled(ServiceCardContent)`
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;
  height: 450px;
  max-height: 450px;
  p {
    font-size: 15px;
    text-align: center;
    height: 100%;
  }
  h6 {
    margin: 0;
    font-size: 1rem;
    text-transform: capitalize;
    margin-top: 10px;
  }
  transition: ${Colors.Transitions};
  &:hover {
    img {
        transform: scale(1.1);
      box-shadow: -1px 2px 9px 1px rgb(0 0 0 / 30%);
      border-radius: 10px;
    }
  }
`;

function Item({ productname, productimg, productdesc }) {
  const ConsultaWapp = `https://api.whatsapp.com/send?phone=5491130080722&text=Hola!%20Quisiera%20hacer%20una%20consulta%20sobre%20el%20producto%20${productname}`;

  return (
    <div
      data-aos="fade-in"
      data-aos-offset="200"
      data-aos-delay="400"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <ServiceCard>
        <ProdCardContent>
          <ImgProds src={productimg} alt="manuelitaeliasproducts" />
          <h6>{productname}</h6>
          <p>{productdesc}</p>
          <NavigationLinks
            href={ConsultaWapp}
            title="Realizar consulta vía Whatsapp"
            target="_blank"
            rel="noreferrer"
          >
            <SecondaryButton>ConsultaR</SecondaryButton>
          </NavigationLinks>
        </ProdCardContent>
      </ServiceCard>
    </div>
  );
}

const MapProducts = () => {
  return (
    <>
      {ProductsArray.map((products, index) => (
        <Grid key={index}>
          <div>
            <Item
              key={index}
              productstitle={products.ProductsTitle}
              productname={products.ProductName}
              productimg={products.ImgProduct}
              productdesc={products.ProductDescription}
            />
          </div>
        </Grid>
      ))}
    </>
  );
};

export default MapProducts;
