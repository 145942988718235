import React from "react";

import MapMedCenter from "../../helpers/MapMedCenter";
import MapCenterMedImgs from "../../helpers/MapCenterMedImgs";
import { MainContainer, Content, SliceColor, SectionTitles } from "../Styles";

const MedCenter = () => {
  return (
    <div>
      <MainContainer background1 padding1>
        <Content color1 padding2>
          <SectionTitles>
            <h4>
              <SliceColor>El c</SliceColor>entro
            </h4>
          </SectionTitles>

          <MapMedCenter />

          <MapCenterMedImgs />
        </Content>
      </MainContainer>
    </div>
  );
};

export default MedCenter;
