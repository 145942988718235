import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Colors, device } from "./Variables";
import { StaticData } from "./Messages";
import DataContact from "../components/DataContact";

const NavBarContainer = styled.div`
  padding: 1rem 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media ${device.mobileS} {
    flex-direction: column;
    gap: 1rem;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 1rem;
  }
  @media ${device.tablet} {
    flex-direction: row;
    gap: 5rem;

  @media ${device.laptop} {
    flex-direction: row;
    gap: 5rem;

  }
`;
const ContainerLinks = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 25px;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  @media ${device.mobileS} {
    flex-direction: column;
    gap: 1rem;
    text-align:center;
    padding: 0;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 1rem;
    text-align:center;
    padding: 0;
  }
  @media ${device.tablet} {
    flex-direction: column;
    gap: 1rem;
    text-align:center;
    padding: 0;

  @media ${device.laptop} {
    flex-direction: row;
    gap: 5rem;

  }
`;
const NavLinks = styled(Link)`
    color: ${Colors.ColorD};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    font-weight: 400;
    &:hover {
        color: ${Colors.ColorC};
        font-weight: 800;
    }
`;

const ContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  h3,
  h4 {
    margin: 0;
    color: ${Colors.ColorD};
    font-weight: 600;
    ${Colors.GeneralFont};
  }
  h4 {
    font-weight: 300;
  }
  @media ${device.mobileS} {
    text-align:center;

  }
  @media ${device.mobileL} {
     text-align:center;

  }
  @media ${device.tablet} {
    text-align: center;

  @media ${device.laptop} {
    text-align: center;

  }
`;

const Header = styled.div`
    background-color: #434343;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
`;
const HeaderRedes = styled.div`
    position: relative;
`;

const NavBarWeb = () => {
    return (
        <div className="NavBarWeb">
            <HeaderRedes>
                <DataContact />
            </HeaderRedes>
            <Header className="animate__animated animate__fadeIn animate__fast">
                <NavBarContainer>
                    <NavLinks to="/">
                        <ContainerLogo>
                            <h3>{StaticData.Name}</h3>
                            <h4>{StaticData.Title}</h4>
                        </ContainerLogo>
                    </NavLinks>
                    <div>
                        <ContainerLinks>
                            <NavLinks to="/">Inicio</NavLinks>
                            <NavLinks to="/biography">Biografía</NavLinks>
                            <NavLinks to="/philosophy">Filosofía</NavLinks>
                            <NavLinks to="/treatement">Tratamientos</NavLinks>
                            <NavLinks to="contactme">Contacto</NavLinks>
                        </ContainerLinks>
                    </div>
                    {/* <div>
            <Redes />
          </div> */}
                </NavBarContainer>
            </Header>
        </div>
    );
};

export default NavBarWeb;
