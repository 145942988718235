import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

import * as animationData from "../animations/animation.json";

const LottieWrapper = styled.div`
  display: block;
  position: absolute;
  z-index: 0;
  bottom: 19rem;
  right: -70rem;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(-255deg);
  opacity: 70%;
  overflow-y: auto;
  filter: sepia(0.7);
`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 0 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,

      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={900}
          width={2000}
          speed={2}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation2() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
