import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { StaticData } from "./Messages";
import { Colors } from "./Variables";
import { MenuComponent } from "./Styles";
import Icons from "./Icons";
import DataContactMobile from "./DataContactMobile";

const HeaderNav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 1rem 0;
    margin: 0 1rem;
`;
const ContainerNavBar = styled.div`
    display: flex;
    flex-direction: column;
    text-align: initial;
    width: 100%;
`;

const Burger = styled.div`
    border-radius: ${Colors.BorderRadius};
    cursor: pointer;
    text-align: center;
    transition: ${Colors.Transitions};
`;
const ContainerLinks = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 25px;
    margin: 1rem;
    border-top: 2px solid #a8a4a452;
    padding-top: 1rem;
`;

const NavLinks = styled(Link)`
    color: ${Colors.ColorD};
    text-decoration: none;
    font-size: 1rem;
    ${Colors.GeneralFont};
    transition: ${Colors.Transitions};
    line-height: 40px;
`;

const ToHome = styled(Link)`
    text-decoration: none;
    transition: ${Colors.Transitions};
    color: ${Colors.ColorD};
`;

const TitleAlignment = styled.div`
    text-align: start;
`;

const Title = styled.p`
    margin: 0;
    font-size: 1.5rem;
`;

const SubTitle = styled(Title)`
    font-size: 0.8rem;
`;

const HeaderRedes = styled.div`
    position: relative;
`;

const NavBarMobile = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };
    return (
        <div className="NavBarMobile">
            <HeaderRedes>
                <DataContactMobile />
            </HeaderRedes>
            <MenuComponent collapsed={collapsed} className="animate__animated animate__fadeIn animate__fast">
                <ContainerNavBar>
                    {(collapsed && (
                        <div onClick={toggle}>
                            <HeaderNav>
                                <TitleAlignment>
                                    <Title>{StaticData.Name}</Title>
                                    <SubTitle>{StaticData.Title}</SubTitle>
                                </TitleAlignment>
                                <Burger onClick={toggle}>
                                    <Icons name="burger2" />
                                </Burger>
                            </HeaderNav>
                            <ContainerLinks>
                                <NavLinks onClick={toggle} to="/">
                                    {"> "}Inicio
                                </NavLinks>
                                <NavLinks onClick={toggle} to="/biography">
                                    {"> "}Biografía
                                </NavLinks>
                                <NavLinks onClick={toggle} to="/philosophy">
                                    {"> "}Filosofía
                                </NavLinks>
                                <NavLinks onClick={toggle} to="/treatement">
                                    {"> "}Tratamientos
                                </NavLinks>
                                <NavLinks onClick={toggle} to="contactme">
                                    {"> "}Contacto
                                </NavLinks>
                            </ContainerLinks>
                        </div>
                    )) || (
                        <>
                            {" "}
                            <HeaderNav>
                                <TitleAlignment>
                                    <ToHome to="/">
                                        <Title>{StaticData.Name}</Title>
                                        <SubTitle>{StaticData.Title}</SubTitle>
                                    </ToHome>
                                </TitleAlignment>
                                <Burger onClick={toggle}>
                                    <Icons name="burger1" />
                                </Burger>
                            </HeaderNav>
                        </>
                    )}
                </ContainerNavBar>
            </MenuComponent>
        </div>
    );
};

export default NavBarMobile;
